import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: require('./locales/en.json')
  },
  es: {
    translation: require('./locales/es.json')
  },
  fr: {
    translation: require('./locales/fr.json')
  },
  de: {
    translation: require('./locales/de.json')
  },
  ar: {
    translation: require('./locales/ar.json')
  },
  hi: {
    translation: require('./locales/hi.json')
  },
  ptbr: {
    translation: require('./locales/pt-br.json')
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('language') || navigator.language.split('-')[0] || 'en', // Detect browser language or use saved language
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false // not needed for React
  }
});

export default i18n;
