import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Apiurl } from 'app/apiconfig';

const baseQuery = fetchBaseQuery({ baseUrl: Apiurl[0].api_url });

const rootApi = createApi({
  baseQuery,
  tagTypes: [
    'Dreams', // url: /dreams/list
    'DreamCategories', // url: /categories work-in
    'Goals', // url: /goals
    'Tasks', // url: /tasks
    'DashboardData', // url: /get-dashboard-data
    'GlobalScore', // url: /global-score
    'Awards', // url: /get-awards
    'Rewards', // url: /get-rewards
    'DashboardCalanderTasks', // url: /dashboard-calender-tasks
    'AllTasksLogs', // url:
    'DashboardTasksCountsData', // url: /dashboard-tasks-count
    'SubuserCompletedTaskCountData', // url: /dashboard-subusers-count
    'Subusers', // url: /get-subusers
    'DashboardNetworthsCount', // url: /dashboard-networths-count
    'Networths', // url: /networths
    'DailyStatusData', // url: /user/daily-report
    'DailyPerformanceReports', // url: /daily-performances-reports
    'NetwothCategories', // url: /networths-chart-filter
    'NetworthsChartFilter', // url: /networths-chart-filter
    'NetworthsBucketList', // url: /networth-buckets
    'User', // url: /get-user
    'UserExtraData', // url: /users-extras
    'TimezoneList', // url: /timezones/list
    'UserBillingData', // url: /user/billing
    'TaskTags', // url: /tags
    'CustomTaskStatusList', // url: /grid-status-list
    'CustomTaskStatusPositions', // url: /grid-status-positions
    'SlackData', // url: /slack/data
    'SlackSettings', // url: /user/get-slack-setting
    'Tickets', // url: /tickets
    'TicketsReplies', // url: /ticket-replies
    'FilteredGoals', // url: /filter-goals
    'SubscriptionPlansList', // url: /plans/list
    'OnboardingStatus', // url: /onboarding-status
    'Timeline', // url: in settings
    'DreamGoalsTasksRewardsCount', // url: /get-dream-data,
    'AssesmentReport',
    'AllStickyNotes',
    'StickyNote',
    'TaskKaizen',
    'TasksHighestPositions', // url: /get-tasks-positions
    'AllTasks'
  ],
  endpoints: (builder) => ({})
});

export default rootApi;
